import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

class HomePage extends Component {
  componentDidMount() {
    //
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Bagoly-Lak</title>
        </Helmet>
        <div className="main">
          <img className="main__background-full" src="assets/background-full.png" alt="Háttérkép" />
          <img
            className="main__background-minimal"
            src="assets/background-minimal.png"
            alt="Háttérkép"
          />
          <img className="main__logo" src="assets/bagoly-lak-logo.png" alt="Logo" />
          <div className="main__menu main__menu--desktop">
            <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
            <div className="main__menu__menus">
              <Link to="/">Bemutató</Link>
              <Link to="/matraszentimre">Mátraszentimre</Link>
              <Link to="/galeria">Galéria</Link>
              <Link to="/hasznos-informaciok">Hasznos információk</Link>
              <Link to="/szabadidos-programok">Szabadidős programok</Link>
              <Link to="/elerhetoseg">Elérhetőség</Link>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="main__menu main__menu--mobile">
                  <img className="main__menu__background" src="assets/menu.png" alt="Menü" />
                  <div className="main__menu__menus">
                    <Link to="/">Bemutató</Link>
                    <Link to="/matraszentimre">Mátraszentimre</Link>
                    <Link to="/galeria">Galéria</Link>
                    <Link to="/hasznos-informaciok">Hasznos információk</Link>
                    <Link to="/szabadidos-programok">Szabadidős programok</Link>
                    <Link to="/elerhetoseg">Elérhetőség</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="content">
                  <div className="introduction">
                    <h1 className="d-none">Bagoly Lak - Információk</h1>

                    <img className="image" src="assets/bagoly-lak.png" alt="Bagoly Lak" />

                    <p className="building-date">Építés éve: 1874</p>

                    <p className="important">
                      Úgy érzi megpihenne a családjával de nem tudja hol tegye?
                    </p>

                    <p className="important">
                      Nem kell tovább keresnie, a Bagoly Lak minden évszakban kiváló választás!
                    </p>

                    <p className="bold">
                      Részben modern kialakítású, részben korhű, mátrai kőből épült házikó. Gyönyörű
                      panoráma, rengeteg szabadidős tevékenység, nyársalás/grillezési lehetőség, és
                      a friss levegő várja nálunk.
                    </p>

                    <div className="booking">
                      <h2>Booking.com</h2>
                      <img
                        className="booking-rating"
                        src="assets/booking-rating.png"
                        alt="Rating"
                      />
                      <img
                        className="booking-desktop"
                        src="assets/booking-desktop.png"
                        alt="Booking"
                      />
                      <img
                        className="booking-mobile"
                        src="assets/booking-mobile.png"
                        alt="Booking"
                      />
                      <div style={{ justifyContent: 'space-between' }}>
                        <div style={{ display: 'inline', width: '90%', marginRight: '15px' }}>
                          <img
                            className="booking-award"
                            src="assets/booking-award-2023.png"
                            alt="Award"
                          />
                        </div>
                        <div style={{ display: 'inline', width: '90%' }}>
                          <img
                            className="booking-award"
                            src="assets/booking-award.png"
                            alt="Award"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="prices">
                      <h2>Szállás ára</h2>
                      <span className="bold">55.000 Ft</span>{' '}
                      <span className="color-primary">/</span> éjszaka
                    </div>

                    <p>Bármilyen kérdés, kérés esetén állunk rendelkezésére!</p>

                    <p>
                      Köszönjük együttműködését és kellemes időtöltést kívánunk: a Global-Line Kft.
                      csapata
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main__separator" />
        </div>
      </>
    );
  }
}

export default HomePage;
